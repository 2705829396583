import { useCallback, useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";

import styled from "styled-components";
import {
  Box,
  Container,
  Input,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";
import {
  awaitTransactionSignatureConfirmation,
  CANDY_MACHINE_PROGRAM,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState,
} from "./candy-machine";
import { AlertState, formatNumber, getAtaForMint, toDate } from "./utils";
import { MintCountdown } from "./MintCountdown";
import { MintButton } from "./MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { sendTransaction } from "./connection";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import { FaDiscord, FaTwitter } from "react-icons/fa";

import MainGif from "./maingif.gif";
import { client } from "./lib/sanity";

import { v4 as uuid } from "uuid";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import img from "./check mark.png";

import bg from "./serverbg3.png";

import "./style.css";
import {
  getParsedNftAccountsByOwner,
  resolveToWalletAddress,
} from "@nfteyez/sol-rayz";
import axios from "axios";
import { isMobile, isBrowser } from "react-device-detect";

const ConnectButton = styled(WalletMultiButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const MintContainer = styled.div`
  display: flex;
  align-items: center;
`;

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
  userId: string;
  userUserName: string;
  onClick: any;
  accessToken: string;
  unique_id: string;
}

const Home = (props: HomeProps) => {
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [open, setOpen] = useState(false);

  const [openDiscord, setOpenDiscord] = useState(false);
  const [discordText, setDiscordText] = useState("");

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [discordUserName, setdiscordUserName] = useState("");

  const [haveNfts, setHaveNfts] = useState(false);

  const [verified, setVerified] = useState(false);

  const [alredyVerified, setAlredyVerified] = useState(false);

  const [fetchUniqueId, setFetchUniqueId] = useState("");

  const [walletNft, setWalletNft] = useState("");

  const [UserData, setUserData] = useState({
    _createdAt: "",
    _id: "",
    _rev: "",
    _type: "",
    _updatedAt: "",
    id: "",
    used: false,
    userName: "",
    walletAddress: "",
  });

  const wallet = useWallet();

  const saveTransaction = async () => {
    setDiscordText("LOADING");
    setOpenDiscord(true);

    // *[_type == 'verifyMobile' || walletAddress == "2ryEgiKn6XX9MihWJFGaXpqkS9ngATZAupoH2cwZzkzt"]

    try {
      await client
        .create({
          _type: "verify",
          _id: props.unique_id,
          walletAddress: wallet?.publicKey,
          id: props.unique_id,
          userName: discordUserName,
        })
        .then((res) => {
          console.log(`Todo was created, document ID is ${res._id}`);
          navigator.clipboard.writeText(props.unique_id);
          setFetchUniqueId(props.unique_id);
          setLoading(false);
          setVerified(true);
        });

      let response = { data: { image: "" } };

      await axios.get(walletNft).then((res) => (response = res));

      setDiscordText(
        "PLEASE GOTO DISCORD AND PASTE YOUR UNIQUE ID IN CHANNEL, *UNIQUE CODE COPIED TO CLIPBOARD*"
      );
      setOpenDiscord(true);
    } catch (err) {
      console.error(err);
      setDiscordText(`${err}`);
    }
  };

  // useEffect(() => {
  //   async function saveLaptopTransaction() {
  //     setDiscordText("LOADING.....");
  //     setOpenDiscord(true);
  //     try {
  //       await client
  //         .create({
  //           _type: "verifyLaptop",
  //           _id: props.unique_id,
  //           walletAddress: wallet?.publicKey,
  //           id: props.unique_id,
  //           userName: props.userUserName,
  //           userId: props.userId,
  //           used: false,
  //         })
  //         .then((res) => {
  //           console.log(`Todo was created, document ID is ${res._id}`);
  //           setLoading(false);
  //         });
  //       let response = { data: { image: "" } };

  //       await axios.get(walletNft).then((res) => (response = res));

  //       const contenido = {
  //         embeds: [
  //           {
  //             title: "Successfully Verified Holder",
  //             description: `We have Successfully verified <@${props.userId}> wallet address : ${wallet?.publicKey} as Fluffy Frens Pre-whitelist Nft Holder`,
  //             footer: {
  //               text: "from Fluffy Verfication",
  //             },
  //             image: {
  //               url: response.data.image,
  //             },
  //           },
  //         ],
  //       };
  //       await fetch(
  //         "https://discord.com/api/webhooks/975031286647357460/dbXxMRZKclkoxbZqaD_aXGJ-ts4Ea_9P74h8E68LhRSHwV41HvxSKPv3smuu1pPH1t00",
  //         {
  //           method: "POST",
  //           body: JSON.stringify(contenido),
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       )
  //         .then((res) => {
  //           console.log(res);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //       setDiscordText("YOU WILL RECEIVE ROLE SOON......");
  //       setFetchUniqueId(props.unique_id);
  //       setVerified(true);
  //     } catch (err) {
  //       console.error(err);
  //       setDiscordText(`${err}`);
  //     }
  //   }
  //   if (props.userId !== "" && props.userUserName !== "" && wallet.connected) {
  //     saveLaptopTransaction();
  //   }
  // }, [props.userId, props.userUserName, wallet.connected]);

  useEffect(() => {
    if (wallet.connected) {
      checkNfts();
      client
        .fetch(
          `*[_type in ["verify"] && walletAddress == "${wallet.publicKey}"]`
        )
        .then((res) => {
          console.log(res);
          // setUserData(res);
          if (res.length > 0) {
            setVerified(true);
            setFetchUniqueId(res[0]._id);
            navigator.clipboard.writeText(res[0]._id);
            setDiscordText(
              "PLEASE GOTO DISCORD AND PASTE YOUR UNIQUE ID IN  VERIFICATION CHANNEL"
            );
            setOpenDiscord(true);
          }
        })
        .catch(console.log);
    }
  }, [wallet.connected]);

  const checkNfts = async () => {
    setDiscordText("LOADING.....");
    setOpenDiscord(true);
    const address = wallet?.publicKey;

    if (address !== null) {
      const publicAddress = await resolveToWalletAddress({
        text: address.toString(),
      });

      const nftArray = await getParsedNftAccountsByOwner({
        publicAddress,
      });

      let f = nftArray.filter(
        (o: any) =>
          o.updateAuthority === "HkeesxHSX5z4uZ6m1jAMFpzU5Wy2CDKCf8HxV23KMdU3"
      );

      if (f.length > 0) {
        setWalletNft(f[0].data.uri);
        setHaveNfts(true);
        setOpenDiscord(false);
      } else {
        setHaveNfts(false);
        setDiscordText("Sorry you don't have any Dino");
        setOpenDiscord(true);
      }
    }
  };

  return (
    <div
      className="MainContainer"
      style={{
        height: "100vh",
        padding: 0,
        maxWidth: "100%",
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >

      <Container
        className="WrapperCenter"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "calc(100vh - 50px)",
          paddingBottom: "30px",
          flexWrap: "wrap",
          // marginTop: "20px",
        }}
      >
        <div>
          <p
            className="VerificationText"
            style={{
              textAlign: "center",
              color: "rgb(255, 255, 255)",
              fontWeight: 600,
              backgroundColor: "#151a1f",
              padding: "11px",
              borderRadius: "6px",
              fontSize: "1.25rem",
              textTransform: "uppercase",
            }}
          >
            🟢 Dinomonz Verification 🟢
          </p>
        </div>
        <div
          className="WrapperCenterWrapper"
          style={{
            display: "flex",
            background: "rgba(0,0,0,0.4)",
            padding: "30px",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div
              className="HeroGifWrapper"
              style={{
                paddingBottom: "10px",
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                className="HeroGif"
                alt="gif"
                src={MainGif}
                width="350px"
                height={"350px"}
                style={{
                  borderRadius: "10px",
                }}
              />
            </div>
            <div>
              <Container maxWidth="xs" style={{ position: "relative" }}>
                <Paper
                  style={{
                    padding: 24,
                    paddingBottom: 10,
                    backgroundColor: "#151A1F",
                    borderRadius: 6,
                  }}
                >
                  {!wallet.connected ? (
                    <>
                      <ConnectButton>Connect Wallet</ConnectButton>
                    </>
                  ) : (
                    <>
                      {haveNfts ? (
                        <>
                          {verified ? (
                            <>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "700",
                                }}
                              >
                                ✅ WALLET VERIFIED ✅
                              </p>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={img}
                                  alt="verified"
                                  width={"170px"}
                                  className="verifiedImg"
                                />
                              </div>
                              <>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      fetchUniqueId
                                    );
                                  }}
                                >
                                  UNIQUE ID: {fetchUniqueId}
                                </p>
                                <p
                                  style={{
                                    color: "red",
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  VERIFY YOURSELF WITH UNIQUE ID <br /> !verify{" "}
                                  {"<UNIQUE_ID>"}
                                  <br />
                                  (Tap on unique code to copy)
                                </p>
                              </>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "700",
                                }}
                              >
                                ✅ WALLET VERIFIED ✅
                              </p>
                              <FormControl className={classes.margin}>
                                <InputLabel htmlFor="input-with-icon-adornment">
                                  Discord Id
                                </InputLabel>
                                <Input
                                  value={discordUserName}
                                  onChange={(e) =>
                                    setdiscordUserName(e.target.value)
                                  }
                                  id="input-with-icon-adornment"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <p>@</p>
                                    </InputAdornment>
                                  }
                                  required
                                  placeholder="e.g. 0xJohn#4882"
                                />
                              </FormControl>
                              <p>UNIQUE ID :- {props.unique_id}</p>

                              <p
                                style={{
                                  color: "red",
                                  textTransform: "uppercase",
                                  fontSize: "19px",
                                  fontWeight: "700",
                                  textAlign: "center",
                                }}
                              >
                                Copy the code before clicking on button
                              </p>
                              <div
                                style={{
                                  width: "100%",
                                  height: "60px",
                                  marginTop: "10px",
                                  marginBottom: "5px",
                                  background:
                                    "linear-gradient(180deg, #604ae5 0%, #813eee 100%)",
                                  color: "white",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                                onClick={saveTransaction}
                              >
                                VERIFY NOW
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p style={{ textAlign: "center", fontWeight: "700" }}>
                            ❌ WALLET VERIFIED ❌
                          </p>
                        </>
                      )}
                    </>
                  )}
                  {/* <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    style={{ marginTop: 7, color: "grey" }}
                  >
                    Powered by Dinomonz
                  </Typography> */}
                </Paper>
              </Container>
            </div>
          </div>
          <Snackbar
            open={alertState.open}
            autoHideDuration={
              alertState.hideDuration === undefined
                ? 6000
                : alertState.hideDuration
            }
            onClose={() => setAlertState({ ...alertState, open: false })}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={() => setAlertState({ ...alertState, open: false })}
              severity={alertState.severity}
            >
              {alertState.message}
            </Alert>
          </Snackbar>
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="error">Insufficient Balance</Alert>
          </Snackbar>
          <Snackbar
            open={openDiscord}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={discordText === "LOADING" ? "info" : "success"}>
              {discordText ? discordText : ""}
            </Alert>
          </Snackbar>
        </div>
      </Container>
    </div>
  );
};

const getCountdownDate = (
  candyMachine: CandyMachineAccount
): Date | undefined => {
  if (
    candyMachine.state.isActive &&
    candyMachine.state.endSettings?.endSettingType.date
  ) {
    return toDate(candyMachine.state.endSettings.number);
  }

  return toDate(
    candyMachine.state.goLiveDate
      ? candyMachine.state.goLiveDate
      : candyMachine.state.isPresale
      ? new anchor.BN(new Date().getTime() / 1000)
      : undefined
  );
};

export default Home;
