import sanityClient from "@sanity/client";

export const client = sanityClient({
  projectId: "d47r8anf",
  dataset: "production",
  apiVersion: "2021-10-21",
  token:
    "skSK5PXj1slwKgumyR8SnlYfUwYIAlA89iSLTXk6bDEjZvudogdbeZBFfVWiEOARsWJSfvgf4vIIYxGMb6Ew3cNQY8At2NmV5U4QZKo0XvhcOGfKpHH1EQTGIoCdIJvcnRQjWxFZaVuJKe8mLl85xdHfEC1rN6A4FLyGPY8xtNxmsViTmnfV",
  useCdn: false,
});
