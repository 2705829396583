import "./App.css";
import { useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";
import Home from "./Home";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
  getBitKeepWallet,
  getBitpieWallet,
  getBloctoWallet,
  getCloverWallet,
  getCoin98Wallet,
  getCoinhubWallet,
  getLedgerWallet,
  getMathWallet,
  getSafePalWallet,
  getSolflareWebWallet,
  getSolongWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { ThemeProvider, createTheme } from "@material-ui/core";
import axios from "axios";

import { v4 as uuid } from "uuid";

import './style.css'

const DiscordOauth2 = require("discord-oauth2");
const crypto = require("crypto");

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!
    );

    return candyMachineId;
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const [accessToken, setAccessToken] = useState("");

  const [unique_id, setUnique_id] = useState("");

  const [code, setcode] = useState("");

  const [userDetails, setUserDetails] = useState({
    id: "",
    username: "",
    avatar: "",
    avatar_decoration: null,
    discriminator: "",

    public_flags: 0,
    flags: 0,
    banner: null,
    banner_color: null,
    accent_color: null,
    locale: "en-US",
    mfa_enabled: false,
  });

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
      getLedgerWallet(),
      getSolletExtensionWallet({ network }),
      getSolletWallet({ network }),
      getSolflareWebWallet({ network }),
      getCoin98Wallet(),
      getMathWallet(),
      getBitpieWallet(),
      getBloctoWallet(),
      getCloverWallet(),
      getSolongWallet(),
      getBitKeepWallet(),
      getCoinhubWallet(),
      getSafePalWallet(),
    ],
    []
  );

  const onClick = async () => {
    const oauth = new DiscordOauth2({
      clientId: "974362090452172872",
      clientSecret: "UUxcq2JZxcJlgFX8RxhZwpTi87mSZXf4",
      redirectUri: window.location.origin + "/",
    });

    const url = await oauth.generateAuthUrl({
      scope: ["identify", "guilds"],
      state: crypto.randomBytes(16).toString("hex"), // Be aware that randomBytes is sync if no callback is provided
    });
    window.location.replace(url);
  };

  const getUserData = async (code_ = "") => {
    if (code_ != "") {
      const oauth = new DiscordOauth2();
      await oauth
        .tokenRequest({
          clientId: "974362090452172872",
          clientSecret: "UUxcq2JZxcJlgFX8RxhZwpTi87mSZXf4",
          redirectUri: window.location.origin + "/",
          code: code_,
          scope: "identify guilds",
          grantType: "authorization_code",
        })
        .then((res: any) => {
          const access_token = res.access_token;
          setAccessToken(access_token);
          if (access_token) {
            oauth.getUser(access_token).then((obj: any) => {
              setUserDetails(obj);
              oauth
                .addMember({
                  accessToken: res.access_token,
                  guildId: "971999072133206086",
                  userId: obj.id,
                  nickname: "george michael",
                  botToken:
                    "OTc0MzYyMDkwNDUyMTcyODcy.GCUVtH.5iQCU8aQ1VMEiySUE8Tv1nubuQ7rsIpldtlZeU",
                  roles: ["974016046291877928"],
                })
                .then(console.log);
            });
          }
        })
        .catch(console.log);
    }
  };

  const sendRole = async () => {
    await axios({
      method: "PUT",
      url: `https://discord.com/api/guilds/971999072133206086/members/${userDetails.id}/roles/974016046291877928`,
      headers: {
        Authorization: `Bot OTc0MjQ4MDQzNjE4NjM1Nzg2.GGMHIv.TQYHLvxuhT8HHE9EXXW4kcYapUZrDiB6uVN5vI`,
        "Content-type": "application/json",
        "content-type": "application/x-www-form-urlencoded",
      },
      data: {
        access_token: accessToken,
      },
    })
      .then(console.log)
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setcode(window.location.search.split("&")[0].split("=")[1]);
    getUserData(code);
  });

  useEffect(() => {
    const id = uuid();
    setUnique_id(id);
  }, [])
  

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Home
              candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
              userId={userDetails.id}
              userUserName={`${userDetails.username}#${userDetails.discriminator}`}
              onClick={onClick}
              accessToken={accessToken}
              unique_id={unique_id}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
